import React from "react";
import Header from "../components/Header.jsx";
import flowChart from "../images/flow_chart.png";
import ClientLogo from "../images/client_logo.svg";
import "../css/Method.css";

function Methods() {
	return (
		<>
			<Header />
			<div className="container" id="methods">
				<div className="row">
					<div className="col-12">
						<div className="methods-text-container">
							<p
								style={{
									fontSize: "28px",
									fontWeight: "bold",
								}}
							>
								Map Methodology
							</p>
							<div className="methods-text-subcontainer">
								<p>
									This evidence map includes 352 peer-reviewed
									publications reporting on clinical trials
									(randomized controlled trials, controlled
									clinical trials, and pre-post studies), and
									is current as of January 2024.
								</p>

								<p>
									The underlying scoping review, which
									identified the articles included in the
									evidence map, used the following modified
									version of{" "}
									<a
										className="custom-link"
										href="https://journals.sagepub.com/doi/10.7453/gahmj.2015.034.suppl"
										target="_blank"
										rel="noopener noreferrer"
									>
										Jain's 2015
									</a>{" "}
									definition of biofield therapies to
									determine which interventions to include:
								</p>

								<p style={{ marginLeft: 24 }}>
									“Biofield therapies are noninvasive
									therapies in which a practitioner explicitly
									works with their own or another living
									system's (e.g., person, nonhuman, animal)
									biofield (interacting fields of energy and
									information that surround living systems)
									locally or remotely to facilitate a healing
									response.”
								</p>
								<b>Article Inclusion Criteria:</b>

								<ol>
									<li>
										Peer-reviewed studies published in
										English, including:
										<ul>
											<li>Scoping reviews</li>
											<li>Systematic reviews</li>
											<li>Integrative reviews</li>
											<li>Meta-analyses</li>
											<li>
												Interventional trials (e.g.,
												randomized controlled trials,
												controlled clinical trials,
												before-and-after study)
											</li>
											<li>
												Observational studies (e.g.,
												prospective or retrospective
												longitudinal cohort studies,
												case-control studies, and
												cross-sectional studies)
											</li>
											<li>
												Case studies and case series
											</li>
										</ul>
									</li>
									<li>
										Humans, animals, or cell models as study
										participants (i.e., receivers of the
										intervention) in which the biofield
										therapy was being delivered between
										practitioner(s) and receiver(s)
									</li>
									<li>
										All clinical outcomes and types of
										control/comparators
									</li>
									<li>
										Observational studies that involved
										surveys reporting on the prevalence of
										biofield therapy usage within a
										population
									</li>
									<li>
										Studies involving multimodal
										interventions, where subjects were
										exposed to various modalities including
										biofield therapies
									</li>
								</ol>
								<b>Article Exclusion Criteria:</b>

								<ol>
									<li>
										Study designs that did not meet the
										inclusion criteria, such as:
										<ul>
											<li>Editorials</li>
											<li>Commentaries</li>
											<li>Book chapters</li>
											<li>
												Conference proceedings/abstracts
											</li>
											<li>Narrative reviews</li>
											<li>Practice guidelines</li>
										</ul>
									</li>
									<li>
										Studies involving plants or inanimate
										objects as recipients
									</li>
									<li>
										Studies in which practitioners
										incorporate tools such as acupuncture
										needles or tuning forks, or employ
										techniques involving manipulation of the
										musculoskeletal system, such as massage
										or cranial sacral therapy
									</li>
									<li>
										Studies where the practitioner served as
										the recipient (working on their own
										system/self-healing)
									</li>
								</ol>
								<p>
									To gain a deeper understanding of the
									methodology employed in this scoping review
									to identify the articles included in the
									evidence map, please see{" "}
									<a
										className="custom-link"
										href="https://osf.io/2hmxz"
										target="_blank"
										rel="noopener noreferrer"
									>
										here
									</a>
									.
								</p>
								<p>
									Future iterations of this evidence map will
									incorporate all study designs and
									populations, and will be updated yearly.
								</p>

								<p>
									To view all of the peer-reviewed publication
									citations identified by this scoping review
									for inclusion, please see here:{" "}
									<a
										className="custom-link"
										href="https://doi.org/10.1089/jicm.2024.0773"
										target="_blank"
										rel="noopener noreferrer"
									>Biofield Therapies Clinical Research Landscape: A Scoping Review and Interactive Evidence Map</a>
									.
								</p>
							</div>
						</div>
						<div className="method-bottom">
							<a
								href="/"
								className="btn btn-primary col-12 col-sm-12 col-md-4 mt-4"
								id="back_to_map_button"
							>
								Back
							</a>
							<div
								className="bottom-client-logo-container"
								style={{
									display: "flex",
									flexDirection: "column",
									marginTop: 25,
								}}
							>
								<p className="upload-excel-container logo-description">
									The Biofield Science Evidence Map was made
									possible <br id="description-break" />
									through funding support from members of:
								</p>
								<a
									href="/"
									style={{
										display: "flex",
										width: "200px",
										height: "60px",
									}}
								>
									<img
										id="client_logo"
										src={ClientLogo}
										alt="ClientLogo"
										className="img-fluid"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Footer /> */}
		</>
	);
}

export default Methods;
