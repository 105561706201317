import React, { useEffect } from "react";
import Header from "../components/Header.jsx";
import ClientLogo from "../images/client_logo.svg";

function About() {
	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		const href = window.location.href.substring(
			window.location.href.lastIndexOf("#") + 1
		);
		if (window.location.href.lastIndexOf("#") > 0) {
			document.getElementById(href)?.scrollIntoView();
		}
	});
	return (
		<>
			<Header />
			<div className="container" id="methods">
				<div className="row">
					<div className="col-12">
						<div className="methods-text-container">
							<div className="methods-text-subcontainer">
								<p
									style={{
										fontWeight: "bold",
										fontSize: "28px",
									}}
								>
									About the Evidence Map
								</p>
								<p>
									The Biofield Science Evidence Map is an
									innovative endeavor in integrative health to
									systematically categorize, catalog and
									visually present all existing peer-reviewed
									clinical studies on biofield therapies. With
									its accompanying scoping review publication:{" "}
									<a 
										className="custom-link"
										target="_blank"
										rel="noopener noreferrer"
										href="https://doi.org/10.1089/jicm.2024.0773">Biofield Therapies Clinical Research Landscape: 
									A Scoping Review and Interactive Evidence Map</a>
									, the Evidence Map is designed to
									improve the accessibility and usefulness of
									scientific evidence pertaining to biofield
									therapies for a diverse range of
									stakeholders.
									<br />
									<br />
									The current version of this Evidence Map is
									up to date as of January 4, 2024. The map
									includes 352 publications reporting on
									clinical trials (randomized controlled
									trials, controlled clinical trials, and
									pre-post studies), as identified through the
									scoping review process. Future updates to
									this Evidence Map will include all study
									designs and populations. To learn about the
									specific methodological process used to
									identify and characterize articles included
									in this evidence map, see the scoping review
									publication{" "}
									<a
										className="custom-link"
										href="https://doi.org/10.1089/jicm.2024.0773"
										target="_blank"
										rel="noopener noreferrer"
									>
										here
									</a>{" "}
									or visit the{" "}
									<a
										className="custom-link"
										href="/methods"
										target="_blank"
										rel="noopener noreferrer"
									>
										Methodology
									</a>{" "}
									section of this site.
									<br />
									<br />
									<p>
										The Biofield Science Evidence Map and
										underlying scoping review are public
										resources made possible by the
										philanthropic support of members of the{" "}
										<a
											className="custom-link"
											href="https://biofieldfellowship.org/the-subtle-energy-funders-collective/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Subtle Energy Funders Collective
										</a>
										.
									</p>
									<p
										style={{
											fontStyle: "italic",
											fontWeight: "bold",
										}}
									>
										The Need for a Biofield Science Evidence
										Map
									</p>
									Subtle energy and biofield healing
									modalities have been used for millennia for
									an array of health conditions and to enhance
									human well-being. However, the scientific
									study and adoption of subtle energy and
									biofield therapies in Western culture have
									been hindered by challenges such as cultural
									barriers and institutional obstacles.
									Furthermore, the research landscape for
									biofield therapies is poorly understood.
									<br />
									<br />
									To advance understanding and evidence-based
									adoption of biofield therapies, more
									rigorous, targeted research is needed for
									scientific validation, including both
									exploratory and confirmatory studies.
									Educational efforts are also essential for
									raising awareness among the public, health
									systems, and research institutions about the
									existing evidence supporting the adoption of
									these therapies and the current state of the
									science. The purpose of this interactive
									Evidence Map is to provide a
									readily-accessible, comprehensive, and
									targeted view of the existing evidence base,
									as well as gaps in the evidence. In so
									doing, the Evidence Map aims to support
									researchers, practitioners, healthcare
									systems, policy-makers, funders and the
									public in decision-making and engagement.
									<br />
									<br />
									<p
										style={{
											fontStyle: "italic",
											fontWeight: "bold",
										}}
									>
										What Is a Scoping Review and an Evidence
										Map?
									</p>
									A scoping review is a robust and systematic
									methodology that aims to identify all
									scientific literature published in a
									specific research area, detailing the
									extent, range, and characteristics of the
									studies in a given field. The purpose of a
									scoping review is to explore the research
									landscape and address questions like "what
									exists?", unlike systematic reviews which
									aim to answer specific clinical questions
									such as "is it effective?" to inform
									clinical practice decisions. To learn more
									about the difference between scoping reviews
									and systematic reviews, see{" "}
									<a
										className="custom-link"
										href="https://bmcmedresmethodol.biomedcentral.com/articles/10.1186/s12874-018-0611-x"
										target="_blank"
										rel="noopener noreferrer"
									>
										here
									</a>
									.
									<br />
									<br />
									An evidence map provides a comprehensive
									visual representation of the results of the
									scoping review to help researchers and other
									stakeholders better understand and
									communicate the available literature, the
									knowledge gaps, and the potential areas for
									future research. Our evidence map is
									designed to be visual, interactive, and
									useful for a variety of different
									stakeholders, to query the cataloged
									evidence, and explore what exists.
									<br />
									<br />
									<p
										style={{
											fontStyle: "italic",
											fontWeight: "bold",
										}}
									>
										How Might I Interact with this Evidence
										Map?
									</p>
									A <b>Researcher</b> may use the Biofield
									Science Evidence Map to inquire about
									existing evidence related to specific
									populations, conditions, therapies, and/or
									outcomes. This exploration can help to
									identify current knowledge and potential
									research gaps, guiding the identification of
									new research opportunities or the
									formulation of targeted research questions
									based on the existing scientific landscape.
									<br />
									<br /> A <b>Practitioner</b> may use the
									Biofield Science Evidence Map to inquire
									about existing scientific evidence
									supporting use for specific health
									conditions and outcomes. This query can
									guide the identification of approaches that
									could be considered for integration into
									patient care based on the available
									evidence.
									<br />
									<br />A <b>Consumer or Patient</b> may use
									the Biofield Science Evidence Map to explore
									the evidence base and determine which
									specific biofield therapies are worth
									integrating into their care routine to
									address a particular condition or concern.
								</p>
								We welcome your suggestions for improvements or
								corrections to this Evidence Map. Please contact
								us via{" "}
								<a
									className="custom-link"
									href="mailto:admin@funderscollective.com?subject=Feedback%20on%20Biofield%20Science%20Evidence%20Map&body=Dear%20Team,%0D%0A%0D%0AI%20have%20some%20feedback%20to%20share%20regarding%20the%20Biofield%20Science%20Evidence%20Map.%20%0D%0A%0D%0AHere%20are%20my%20thoughts:%0D%0A%0D%0A%0D%0A-%20%0D%0A%0D%0AThank%20you%20for%20the%20opportunity%20to%20provide%20input.%0D%0A%0D%0ASincerely,%0D%0A[Your%20Name]"
								>
									admin@funderscollective.com
								</a>
								.
								<br />
								<br />
								<p
									style={{
										fontSize: "28px",
										fontWeight: "bold",
									}}
								>
									<a id="how_to_use" href="#how_to_use">
										How To Use the Biofield Science Evidence
										Map
									</a>
								</p>
								This evidence map was developed to visually
								showcase research conducted on biofield
								therapies to date, offering an interactive
								platform for exploration and learning. Below are
								instructions to help you navigate.
								<br />
								<br />
								<p
									style={{
										fontWeight: "bold",
									}}
								>
									Navigating the Evidence Map
								</p>
								<ul>
									<li>
										Scan and scroll the map for bubbles;
										each represents areas where research has
										been conducted. The number inside the
										bubble indicates the quantity of
										peer-reviewed publications. The color
										signifies the direction of results:
										positive, mixed, not-significant, or
										negative.
									</li>
									<li>
										Click on a bubble to reveal the titles
										of the corresponding articles. Select an
										article to view a curated abstract
										including the study's population,
										intervention, methods, and outcomes,
										along with a link to the full
										publication if you’d like to read
										further.
									</li>
									<li>
										From the abstract page, to see
										additional articles in the same category
										simply check out "Other reports in this
										category" on the right side of the page.
									</li>
								</ul>
								Please note that some articles may appear in
								multiple categories within the Evidence Map, as
								they may involve multiple populations or
								evaluate various outcomes/symptoms.
								<br />
								<br />
								<p
									style={{
										fontWeight: "bold",
									}}
								>
									Using Filters to Identify Articles
								</p>
								<ul>
									<li>
										Above the map, you'll find both Basic
										Filters and Advanced Filters. These help
										you narrow down articles based on
										specific criteria.
									</li>
									<li>
										Use the search bar function to directly
										locate articles by using key terms to
										identify your desired content. The
										search function will scan both the
										filter terms and article summaries to
										pinpoint relevant articles.
									</li>
								</ul>
								By following these instructions, you'll be able
								to efficiently explore the Biofield Science
								Evidence Map and uncover valuable insights into
								biofield therapies.
								<br />
								<br />
								<p
									id="multiple-articles"
									style={{
										fontWeight: "bold",
									}}
								>
									Why Some Articles May Appear Multiple Times
								</p>
								In the evidence map, when a study compares different biofield therapies 
								(such as Reiki vs. Therapeutic Touch) or when participants receive more 
								than one type of therapy (such as combination of Reiki plus Therapeutic 
								Touch vs. another therapy), the study is shown in multiple bubbles for 
								each biofield therapy represented. This also applies to studies where 
								participants receive different therapies or combinations of therapies 
								at different times (crossover studies). As a result, some studies may 
								appear more than once in the evidence map.
								<br />
								<br />
								For example:
								<ul>
									<li>In Seskevich et al. (2004), Healing Touch and Intercessory Prayer were tested in separate groups.</li>
									<li>In Krucoff et al. (2005), participants received a combination of Healing Touch and Intercessory Prayer.</li>
									<li>In Wirth et al. (1996), participants in a crossover study received multiple biofield therapies at different times, including Therapeutic Touch, Reiki, LeShan, and External Qigong.</li>
								</ul>

								<br />
								<h5>Acknowledgments</h5>
								<p>
									The following people deserve credit and
									thanks for their diligent work in conducting
									the scoping review and creating this
									Evidence Map:
								</p>
								<span style={{ fontWeight: "bold" }}>
									Overall Project Director <br />
								</span>
								<span>Meredith Sprengel, MS</span> <br />
								<br />
								<span style={{ fontWeight: "bold" }}>
									Scoping Review Subject Matter Expert <br />
								</span>
								<span>Cindy Crawford, BS </span>
								<br />
								<br />
								<span style={{ fontWeight: "bold" }}>
									Scoping Review Manager
								</span>
								<br />
								<span>Lynn Teo, DAc, MS</span>
								<br />
								<br />
								<span style={{ fontWeight: "bold" }}>
									Review Team
								</span>
								<br />
								<span>
									Samantha Allen, MS <br />
									Liscia Beenhakker, MS <br />
									Hannah Biddell, MS <br />
									Cara Dochat, PhD <br />
									Natalie Dyer, PhD <br />
									Noortje IJssennagger, PhD <br />
									Ryesa Mansour, BS <br />
									Patrick McConnell, PhD <br />
									Nida Paracha, MS <br />
									Anisha Saddy, MS <br />
									Ryan Sliwak, MS <br />
									Benjamin Smith, BA
								</span>
								<br />
								<br />
								<br />
								<p>
									The Biofield Science Evidence Map was built
									with{" "}
									<a
										className="custom-link"
										href="https://evidencemap.com/"
										target="_blank"
										rel="noopener noreferrer"
										style={{ fontWeight: "bold" }}
									>
										evidencemap.com
									</a>
									.
								</p>
							</div>
						</div>

						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<a
								href="/"
								className="btn btn-primary col-12 col-sm-12 col-md-4 mt-4"
								id="back_to_map_button"
							>
								Back
							</a>
							<div
								className="bottom-client-logo-container"
								style={{
									display: "flex",
									flexDirection: "column",
									marginTop: 25,
								}}
							>
								<p className="upload-excel-container logo-description">
									The Biofield Science Evidence Map was made
									possible <br id="description-break" />
									through funding support from members of:
								</p>{" "}
								<a
									href="/"
									style={{
										display: "flex",
										width: "200px",
										height: "60px",
									}}
								>
									<img
										id="client_logo"
										src={ClientLogo}
										alt="ClientLogo"
										className="img-fluid"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Footer /> */}
		</>
	);
}

export default About;
